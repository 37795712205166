import { ToastContainer } from "react-toastify"

import background from "images/abstractBack3.webp"
import elogo from "images/eLogo.webp"

import { RoutesContainer } from "./RoutesContainer"

const App: React.FC = () => (
  <>
    <div className="flex h-full w-full overflow-hidden mb-10 flex-col md:flex-row lg:h-screen">
      <div className="flex w-full bg-white items-center justify-center relative md:w-3/6">
        <img alt="cover" src={elogo} className="absolute w-3/4" />
        <img alt="background" src={background} className="w-full h-52 md:h-full" />
      </div>
      <div className="h-full w-full overflow-y-auto p-5 xl:px-40">
        <RoutesContainer />
      </div>
    </div>
    <ToastContainer autoClose={10000} theme={"light"} position={"bottom-left"} />
  </>
)

export { App }
