import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink } from "@fortawesome/pro-light-svg-icons"
import { FallbackProps } from "react-error-boundary"
import { FC } from "react"
import { Button } from "primereact/button"

import { CustomError } from "../types"

const NotFoundErrorView: FC<Props> = ({ error, resetErrorBoundary }) =>
{
    const message = error.cause?.message ?? "Not found"

    return (
        <div className="flex items-center justify-center w-full h-screen">
            <div className="text-center">
                <p className="text-primary">404 Error</p>
                <h1 className="text-4xl py-2 font-bold">Resource not found</h1>
                <p className="text-sm text-gray-400 pt-2 pb-4">{message}</p>
                <Button onClick={resetErrorBoundary} className="text-sm text-primary hover:text-primary-hover">
                    <span>
                        Go to Home <FontAwesomeIcon icon={faExternalLink} className="pl-1" />
                    </span>
                </Button>
            </div>
        </div>
    )
}

type Props = Pick<FallbackProps, "resetErrorBoundary"> & { error: CustomError }

export { NotFoundErrorView }
