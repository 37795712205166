import { FC } from "react"
import { FallbackProps } from "react-error-boundary"

import { InternalServerErrorView } from "./InternalServerErrorView"
import { NotFoundErrorView } from "./NotFoundErrorView"
import { UnauthorizedErrorView } from "./UnauthorizedErrorView"
import { CustomError } from "../types"

const ErrorContainer: FC<FallbackProps> = ({ error, resetErrorBoundary }) =>
{
    const code = (error as CustomError).cause?.name

    switch (code)
    {
        case "401":
            return <UnauthorizedErrorView />
        case "404":
            return <NotFoundErrorView error={error as CustomError} resetErrorBoundary={resetErrorBoundary} />
        default:
            return <InternalServerErrorView error={error as CustomError} />
    }
}

export { ErrorContainer }
