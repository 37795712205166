import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink } from "@fortawesome/pro-light-svg-icons"
import { FC } from "react"

import { CustomError } from "../types"

const InternalServerErrorView: FC<Props> = ({ error }) =>
{
    const message = error.cause?.message ?? "Internal server error"

    return (
        <div className="flex items-center justify-center w-full h-screen">
            <div className="text-center">
                <p className="text-primary">500 Error</p>
                <h1 className="text-4xl py-2 font-bold">{error.message}</h1>
                <p className="text-sm text-gray-400 pt-2 pb-4">{message}</p>
                <a href="/" className="p-button text-sm button-primary">
                    <span>
                        Go to Home <FontAwesomeIcon icon={faExternalLink} className="pl-1" />
                    </span>
                </a>
            </div>
        </div>
    )
}

type Props = { error: CustomError }

export { InternalServerErrorView }
