import { useParams } from "react-router-dom"

import { AuthProvider } from "auth"
import { ONBOARD_VIEWS } from "utils"

import { OnboardOrganization } from "./OnboardOrganization"
import { OnboardPatient } from "./OnboardPatient"

const OnboardContainer: React.FC<IProps> = ({ onboardView }) =>
{
    const { token } = useParams()

    if (!token)
    {
        throw new Error("Unauthorized", {
            cause: {
                name: "401",
                message: "Sorry, you don't shall pass. If you think it is a mistake, contact to support.",
            },
        })
    }

    switch (onboardView)
    {
        case ONBOARD_VIEWS.ORGANIZATION:
            return <AuthProvider token={token}>
                <OnboardOrganization />
            </AuthProvider>
        case ONBOARD_VIEWS.PATIENT:
            return <OnboardPatient orgId={token} />
        default:
            return null
    }
}

type IProps = {
    onboardView: ONBOARD_VIEWS
}

export { OnboardContainer }
