import { PropsWithChildren, createContext, useMemo, useContext } from "react"

const AuthContext = createContext<State | undefined>(undefined)
AuthContext.displayName = "AuthContext"

const AuthProvider: React.FC<PropsWithChildren<Props>> = ({ children, token }) => {
    const value: State = useMemo(() => ({ token }), [token])

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => {
    const context = useContext(AuthContext)

    if (!context) {
        throw new Error("useAuth must be used within a AuthProvider")
    }

    return context
}

type State = {
    token: string
}

type Props = {
    token: string
}

export { AuthProvider, useAuth }