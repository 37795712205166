import { useEffect, FC } from "react"

import { useSettings } from "../hooks/useSettings"

const ApplySettings: FC<Props> = ({ orgId, isPatientPage }) =>
{
    const { settings } = useSettings(orgId)

    useEffect(() =>
    {
        if (settings)
        {
            let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']")

            if (!link)
            {
                link = document.createElement("link")
                link.rel = "icon"
                document.getElementsByTagName("head")[0].appendChild(link)
            }

            link.href = settings.url

            document.title = "Onboarding"

            if (isPatientPage)
            {
                document.title = `${settings.name ?? "no name"} - Patient Portal Registration`
            }
        }
    }, [settings])

    return null
}

type Props = {
    orgId: string
    isPatientPage?: boolean
}

export { ApplySettings }
