import { Route, Routes } from "react-router-dom"

import { NotFoundView } from "errors"
import { Home } from "home"
import { OnboardContainer } from "onboard"
import { ONBOARD_VIEWS } from "utils"

const RoutesContainer: React.FC = () => <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/welcome/:token/patient" element={<OnboardContainer onboardView={ONBOARD_VIEWS.PATIENT} />} />
    <Route path="/welcome/:token" element={<OnboardContainer onboardView={ONBOARD_VIEWS.ORGANIZATION} />} />
    <Route path="*" element={<NotFoundView />} />
</Routes>

export { RoutesContainer }